import React, { useEffect, useState } from 'react'
import GameList from './GameList'
import classes from './GameList.module.scss';
import axios from 'axios';
import { getBanners } from '../../utils/Api';

export default function GameCarousel({type}) {
  const [promoBanners,setPromoBanners] = useState([]);

  useEffect(() => {
    axios.get(getBanners(1,10)).then((res) => {
        if (res.data.status === 0 && res.data.banners.length > 0) {
          console.log('re',res)
            const bannerComponents = res.data.banners.map((value, index) => (
              <GameList
              key={`${value.id}-${index}`}
              img={value.thumbnailImage}
              promoTxt={value.title}
              buttonText={value.buttonText}
              link={value.linkedPage}
              type={value.type}
              linkedArticle={value.linkedArticle}
              />
            ));
            setPromoBanners(bannerComponents);
        }
    }).catch((res) => {
      console.log(res);
    })
}, []);

  return (
    <div className={`${classes.scrollContainer} ${classes[type]}`}>
        {promoBanners}
    </div>
  )
}
