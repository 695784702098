import React, { useState } from 'react';
import classes from './ToolTip.module.scss';

const Tooltip = ({ message }) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleMouseEnter = () => setIsVisible(true);
    const handleMouseLeave = () => setIsVisible(false);

    return (
        <div className={classes.tooltipContainer}>
            <span
                className={classes.tooltipIcon}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                ?
            </span>
            {isVisible && <div className={classes.tooltipBox}>{message}</div>}
        </div>
    );
};

export default Tooltip;
