import React from 'react'
import mIcon from '../../assets/Icons/m-icon.svg';
import classes from './Loader.module.scss';

export default function LoaderFallback() {
  return (
    <div className={classes.fallBack_cnt}>
        <div className={classes.white_dot}/>
        <div className={classes.green_cnt}>
            <img src={mIcon} className={classes.mIcon} alt=''/>
        </div>
        <div className={classes.white_dot}/>
    </div>
  )
}
