import React from 'react';
import classes from './Table.module.scss';
import moment from 'moment/moment';

export default function Table({
    type,
    data,
    bet,
    titles,
    keyMap,
    showPagination,
    pageSize,
    PageRows,
    currentPage,
    totalAmount,
    prevPage,
    nextPage
}) {
    const evaluateOdds = (oddsString) => {
        const oddsStringStr = oddsString.toString();

        const multiplicationRegex = /(\d+(\.\d+)?)\s*\*\s*(\d+(\.\d+)?)/;
        const match = oddsStringStr.match(multiplicationRegex);

        if (match) {
            const firstNumber = parseFloat(match[1]);
            const secondNumber = parseFloat(match[3]);
            return firstNumber * secondNumber;
        }

        return parseFloat(oddsStringStr.replace(/,/g, ''));
    };

    const startIndex = (currentPage - 1) * pageSize;
    const firstRow = startIndex + 1;
    const endRow = Math.min(startIndex + pageSize, totalAmount);

    return (
        <>
            <table className={classes.table}>
                <thead>
                    <tr>
                        {titles.map((title, index) => (
                            <th key={index}>{title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex} className={rowIndex % 2 === 0 ? classes.evenRow : classes.oddRow}>
                            {titles.map((title, cellIndex) => {
                                const mappedKey = keyMap[title];
                                let cellValue;

                                if (type === "multiplier" && title === "Winnings") {
                                    const oddsNumber = evaluateOdds(row.oddsString);
                                    const baseBet = parseFloat(bet);

                                    if (!isNaN(oddsNumber) && !isNaN(baseBet)) {
                                        const winnings = oddsNumber * baseBet;
                                        cellValue = winnings.toLocaleString();
                                    } else {
                                        cellValue = '-';
                                    }
                                } else if (title === "odds") {
                                    cellValue = row.oddsString;
                                } else if (title.toLowerCase().includes("date")) {
                                    cellValue = moment(new Date(row[mappedKey])).format('MM/DD/YYYY');
                                }else {
                                    cellValue = row[mappedKey];
                                }
                                if (cellValue === 'initDeposit') {
                                    cellValue = 'Deposit initiated';
                                }
                                if (cellValue === 'userDeposit') {
                                    cellValue = 'Deposit Confirmed';
                                }


                                return (
                                    <td
                                        key={cellIndex}
                                        className={
                                            type === "multiplier" && title === "Game"
                                                ? `${classes.multiplierGameFont}`
                                                : ''
                                        }
                                    >
                                        {cellValue !== undefined ? cellValue : '-'}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>

            {showPagination && (
                <div className="paginationContainer">
                    <span className="rowsLabel">Rows per page: </span>
                    <select
                        value={pageSize}
                        onChange={(event) => PageRows(parseInt(event.target.value, 10))}
                        className="rowsDrop"
                    >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                    </select>
                    <span className="paginationString">
                        <b>{firstRow}</b> - {endRow} of {totalAmount}
                    </span>
                    <button
                        onClick={prevPage}
                        disabled={currentPage === 1}
                        className="thumbFilter"
                    >
                        <span className={"arrowIcon" + " " + "previewArrow"} />
                    </button>
                    <button
                        onClick={nextPage}
                        disabled={currentPage * pageSize >= totalAmount}
                        className="thumbFilter"
                    >
                        <span className={"arrowIcon" + " " + "nextArrow"} />
                    </button>
                </div>
            )}
        </>
    );
}
