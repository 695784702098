import React, { useState } from 'react';
import classes from './UserContainers.module.scss';
import InputField from '../../components/InputField/InputField';
import ApiClients from '../../utils/ApiClients';
import { usePopup } from '../../utils/PopupContext';
import { updateClientAdditionalInfo } from '../../utils/Api';
import { useDispatch } from 'react-redux';
import { getUserAdditionalData } from '../../store/walletSlice';
import Loader from '../Loader/Loader';
import Returnmessage from '../Returnmessage/Returnmessage';

export default function EditProfile({ type, title }) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [loading, setLoading] = useState(false);
    const [returnMessage, setReturnMessage] = useState('');
    const { closePopup, handlePopupToggle } = usePopup();
    const dispatch = useDispatch();

    const reset = () => {
        setFirstName('');
        setLastName('');
    }

    const editProfileFct = (e) => {
        e.preventDefault();
        let url = updateClientAdditionalInfo(localStorage.getItem('clientId'));
        let dto = [
            { key: "PFName", value: firstName, },
            { key: "PLName", value: lastName }]
        if (firstName && lastName) {
            setLoading(true);
            ApiClients.post(url, dto).then((res) => {
                setLoading(false);
                if (res.data.status == 0) {
                    dispatch(getUserAdditionalData());
                    closePopup();
                }
                else {
                    setReturnMessage(res.data.message);
                    setTimeout(() => {
                        setReturnMessage('');
                    }, 3000)
                }
            })
        }
    }

    return (
        <div className='overlay'>
            <div className={`${classes.auth_cnt} ${classes[type]}`}>
                <div className={classes.content}>
                    <div className={classes.header}>
                        <span className={classes.title}>{title}</span>
                        <div className={classes.close_btn} onClick={closePopup}>
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                        </div>
                    </div>
                    <div className={classes.auth_body}>
                        <form className={classes.right_section} onSubmit={(e) => editProfileFct(e)}>
                            <InputField required={true} label="first name" type="text" placeholder="Enter your first name" inputValueProp={firstName} onChangeInput={setFirstName} />
                            <InputField required={true} label="last name" type="text" placeholder="Enter your last name" inputValueProp={lastName} onChangeInput={setLastName} />
                            <div className={classes.action_cnt2}>
                                <span className={classes.action_label}>Change your email? </span>
                                <button className={classes.action_btn} onClick={() => handlePopupToggle('editEmail')} type='button'>update email</button>
                            </div>
                            <div className={classes.action_cnt_sm}>
                                <button className={classes.reset_btn} onClick={reset} type='button'>reset</button>
                                <button className={classes.sign_in_btn + ' ' + classes.save_btn} type='submit'>{loading ? <Loader /> : 'save'}</button>
                            </div>
                        </form>
                    </div>
                    {returnMessage !== '' && <Returnmessage type="error" message={returnMessage} />}
                </div>
            </div>
        </div>
    )
}
