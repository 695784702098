import { createSlice } from "@reduxjs/toolkit";
import * as apis from '../utils/Api';
import axios from "axios";
import ApiClients from '../utils/ApiClients';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    loader: null,
    loader2: null,
    status: null,
    message: null,
    tokenValid: null,
    wallet: null,
    depositBalance: null,
    winningBalance: null,
    frozenBalance: null,
    regMessage: null,
    loginMessage: null,
  },
  reducers: {
    loginAuth: (state, action) => {
      state.status = action.payload.status;
      state.loader = action.payload.loader;
      state.message = action.payload.message;
    },
    initiateLogin: (state, action) => {
      state.loader = action.payload;
    },
    initiateRegister: (state, action) => {
      state.loader2 = action.payload;
    },
    authSuccess: (state, action) => {
      state.token = action.payload;
    },
    authCheckSuccess: (state) => {
      state.tokenValid = true;
    },
    initiateOtp: (state, action) => {
      state.loader = action.payload;
    },
    updateWallet: (state, action) => {
      state.wallet = action.payload.wallet;
      state.depositBalance = action.payload.depositBalance;
      state.winningBalance = action.payload.winningBalance;
      state.frozenBalance = action.payload.frozenBalance;
    },
    verifySuccess: (state, action) => {
      state.token = action.payload;
    },
    verifyFail: (state, action) => {
      state.message = action.payload;
    },
    authRegFail: (state, action) => {
      state.regMessage = action.payload;
    },
    authError: (state, action) => {
      state.loginMessage = action.payload;
    }
  },
});

export const { loginAuth, initiateLogin, authError, authSuccess, authCheckSuccess, updateWallet, initiateRegister, initiateOtp, verifySuccess, verifyFail, authRegFail } = authSlice.actions;

export const getWallet = () => {
  return dispatch => {
    let url = apis.walletApi(localStorage.getItem('clientId'));

    ApiClients.get(url)
      .then(response => {
        if (response.data.status == 0) {
          localStorage.setItem('balance', response.data.total);
          dispatch(updateWallet({ wallet: response.data.total, depositBalance: response.data.totalDeposit, winningBalance: response.data.totalWinnings, frozenBalance: response.data.totalFrozen }));

        } else {
          // dispatch(updateMessage(response?.data?.message));
        }
      })
      .catch(error => {
        // dispatch(updateMessage(error.message));
      });
  };
};

export const auth = (username, password, closePopup, handlePopupToggle) => {
  return (dispatch) => {
    dispatch(initiateLogin(true));
    const url = apis.userLogin;
    let authBody = {
      browser: "string",
      deviceToken: "string",
      handSetDetails: "string",
      imei1: "string",
      imei2: "string",
      modelNumber: "string",
      operatingSystemVersion: "string",
      operatingSytem: "android",
      password: password,
      serialNumber: "string",
      usernameOrMsisdn: '234' + username
    };
    axios.post(url, authBody).then(response => {
      dispatch(initiateLogin(false));
      if (response.data.status == 0) {
        localStorage.removeItem('unverified_msisdn');
        localStorage.setItem('token', response.data.token.token);
        localStorage.setItem('msisdn', response.data.msisdn);
        localStorage.setItem('operator', response.data.operator);
        localStorage.setItem('clientId', response.data.clientId);
        localStorage.setItem('refreshToken', response.data.refreshToken.token);
        localStorage.setItem('tokenExpiry', response.data.token.ttl);
        localStorage.setItem('tokenIssueTime', new Date());
        closePopup();
        response.data.email ? localStorage.setItem("email", response.data.email) : localStorage.removeItem("email");
        response.data.is_verified ? localStorage.setItem("emailVerified", response.data.is_verified) : localStorage.removeItem("emailVerified");
        dispatch(authSuccess(response.data.token));
        dispatch(authCheckSuccess());
        dispatch(getWallet());
      }
      else if (response.data.status === -2) {
        localStorage.setItem('unverified_token', response.data.token);
        localStorage.setItem('unverified_msisdn', username);
        handlePopupToggle("otp")
      } else {
        dispatch(authError(response.data.message));
        setTimeout(() => {
          dispatch(authError(null));
        }, 2000)
      }
    })
      .catch(err => {
        console.log(err);
      });
  };
};

export const logout = () => {
  localStorage.clear();
  window.location.href = '/';
  return (dispatch) => {
    dispatch(authSuccess(null));
  }
}

export const verifyMsisdn = (verificationCode, closePopup) => {
  let msisdn = localStorage.getItem('unverified_msisdn');
  return dispatch => {
    const authData = {
      accessChannel: 'WEB',
      msisdn: msisdn,
      otp: verificationCode
    };
    let url = apis.verifyMsisdnApi;
    dispatch(initiateOtp(true));
    axios.post(url, authData)
      .then(response => {
        dispatch(initiateOtp(false));
        if (response.data.status === 0) {
          localStorage.removeItem('unverified_msisdn');
          localStorage.removeItem('unverified_token');
          localStorage.setItem('msisdn', msisdn);
          localStorage.setItem('username', response.data.username);
          localStorage.setItem('token', response.data.token.token);
          localStorage.setItem('refreshToken', response.data.refreshToken.token);
          localStorage.setItem('tokenExpiry', response.data.token.ttl);
          localStorage.setItem('clientId', response.data.clientId);
          localStorage.setItem('operator', response.data.operator);
          dispatch(authSuccess(response.data.token.token));
          dispatch(authCheckSuccess());
          dispatch(getWallet());
          closePopup();
        } else {
          dispatch(verifyFail(response.data.message));
          setTimeout(() => {
            dispatch(verifyFail(null));
          }, 2000)
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export const register = (
  mobile,
  operator,
  email,
  password,
  dob,
  promoCode,
  handlePopupToggle,
  termsAndConditions
) => {
  return dispatch => {
    dispatch(initiateRegister(true));

    const authData = {
      accessChannel: 'WEB',
      msisdn: mobile,
      operator: operator,
      email: email,
      birthdate: dob,
      password: password,
      promoCode: promoCode,
      termsAndConditions: termsAndConditions
    };
    let url = apis.registerUser;

    axios.post(url, authData)
      .then(response => {
        dispatch(initiateRegister(false));
        if (response.data.status === 0) {
          localStorage.setItem('unverified_msisdn', mobile);
          handlePopupToggle("otp")
        }
        else {
          dispatch(authRegFail(response.data.message));
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export default authSlice.reducer;