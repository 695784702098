import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const prevPath = useRef(null);

  useEffect(() => {
    const currentMainPath = pathname.split("/")[1];
    const previousMainPath = prevPath.current?.split("/")[1];

    if (currentMainPath !== previousMainPath) {
      window.scrollTo(0, 0);
    }

    prevPath.current = pathname;
  }, [pathname]);

  return null;
}
