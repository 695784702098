import React, { Fragment, useEffect, useState } from 'react'
import logo from '../../assets/Logos/mrWins-logo.svg';
import classes from './Header.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import wallet_icon from '../../assets/Icons/wallet.svg';
import user_icon from '../../assets/Icons/user.svg';
import logout_icon from '../../assets/Icons/logout.svg';
import ResetPassword from '../UserContainers/ResetPassword';
import { usePopup } from '../../utils/PopupContext';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/authSlice';
import Otp from '../UserContainers/Otp';
import EditProfile from '../UserContainers/EditProfile';
import PaymentProfile from '../UserContainers/PaymentProfile';
import SuccessError from '../UserContainers/SuccessError';
import EditEmail from '../UserContainers/EditEmail';
import ResetPassNmbr from '../UserContainers/ResetPassNmbr';
import PaystackResponse from '../UserContainers/PaystackResponse';
import PaymentResponse from '../UserContainers/PaymentResponse';
import SignInSignUp from '../UserContainers/SignInSignUp';
import EstimatePopup from '../UserContainers/EstimatePopup';

export default function Header() {
    const [mobileMenu, setMobileMenu] = useState(false);
    const [btnstate, setBtnState] = useState("closed");
    const [userDropDown, setUserDropDown] = useState(false);
    const isAuthenticated = localStorage.getItem('token');
    const mobileNumber = localStorage.getItem('msisdn');
    const { activePopup, handlePopupToggle } = usePopup();
    const [balance, setBalance] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const wallet = useSelector((state) => state.auth.wallet);
    const ussdPaystackShortCode = useSelector((state) => state.wallet.ussdPaystackShortCode);
    const paystackAmount = useSelector((state) => state.wallet.paystackAmount);
    const paystackAccountNumber = useSelector((state) => state.wallet.paystackAccountNumber);
    const paystackAccountName = useSelector((state) => state.wallet.paystackAccountName);
    const paystackBankName = useSelector((state) => state.wallet.paystackBankName);
    const paymentReference = useSelector((state) => state.wallet.paymentReference);


    useEffect(() => {
        if (wallet !== null) {
            setBalance(wallet)
            console.log('wallet', wallet);
        } else setBalance(localStorage.getItem('balance'));
    }, [wallet])

    const openMobileMenu = () => {
        setMobileMenu(!mobileMenu);
        if (mobileMenu) {
            setBtnState('closed');
            document.body.classList.remove('mobileMenuOpen');
        } else {
            setBtnState('open');
            document.body.classList.add('mobileMenuOpen');
        }
    }

    const toggleUserAct = () => {
        setUserDropDown(!userDropDown);
    }

    const closeMenu = () => {
        setMobileMenu(false);
        setBtnState('closed');
        document.body.classList.remove('mobileMenuOpen');
    }

    const logout_fct = () => {
        dispatch(logout);
        document.body.classList.remove('mobileMenuOpen');
    }

    return (
        <Fragment>
            <header className={classes.header}>
                <div className='container'>
                    <div className={classes.header_content}>
                        <Link to='/' className={classes.logo_link}>
                            <img src={logo} className={classes.logo + ' ' + [mobileMenu && classes.logo_big]} alt='logo' loading="lazy" />
                        </Link>
                        <nav className={classes.navbar}>
                            <ul className={`${classes.list} ${classes.header_nav} ${mobileMenu
                                ? classes.open
                                : [!mobileMenu ? classes.close : ""]
                                }`}>
                                {isAuthenticated && <div className={classes.logged_in_mngt}>
                                    <div className={classes.logged_in_user} onClick={() => {
                                        navigate('/Dashboard/Profile');
                                        closeMenu();
                                    }}>
                                        <img src={user_icon} className={classes.logged_in_icons} alt='logged_in_icon' loading="lazy" />
                                        <span className={classes.logged_in_cntry_code}>
                                            +234
                                            <span className={classes.loggeed_in_nmbr}>
                                                {mobileNumber.slice(3)}
                                            </span>
                                        </span>
                                    </div>
                                    <div className={classes.wallet_cnt + ' ' + classes.menu_wallet} onClick={() => { navigate('/Dashboard/WalletAndTransaction');  closeMenu(); }}>
                                        <img src={wallet_icon} alt='wallet icon' className={classes.wallet_icon} loading="lazy" />
                                        <span className={classes.wallet_amount}>
                                            {balance ? Number(balance)?.toLocaleString() : 0}
                                            <span className={classes.currency}>{process.env.REACT_APP_CURRENCY}</span>
                                        </span>
                                    </div>
                                </div>}
                                <Link to='/game' className={classes.list_link} onClick={closeMenu}>
                                    <li className={classes.list_item}>play now</li>
                                </Link>
                                <Link to='/HowToPlay' className={classes.list_link} onClick={closeMenu}>
                                    <li className={classes.list_item}>how to play</li>
                                </Link>
                                <Link to='/winestimation' className={classes.list_link} onClick={closeMenu}>
                                    <li className={classes.list_item}>estimate your wins</li>
                                </Link>
                                <Link to='/DrawResults' className={classes.list_link} onClick={closeMenu}>
                                    <li className={classes.list_item}>results</li>
                                </Link>
                                <div className={classes.logged_in_logout_cnt}>
                                    {isAuthenticated && <div className={classes.logged_in_logout_inside}>
                                        <img src={logout_icon} alt='logout icon' loading="lazy" />
                                        <span className={classes.mngt_label + ' ' + classes.logout_label} onClick={logout_fct}>logout</span>
                                    </div>}
                                </div>
                            </ul>
                            <div className={classes.action_cnt}>
                                {isAuthenticated &&
                                    <div className={classes.wallet_cnt} onClick={() => navigate('/Dashboard/WalletAndTransaction')}>
                                        <img src={wallet_icon} alt='wallet icon' className={classes.wallet_icon} loading="lazy" />
                                        <span className={classes.wallet_amount}>
                                            {balance ? Number(balance)?.toLocaleString() : 0}
                                            <span className={classes.currency}>{process.env.REACT_APP_CURRENCY}</span>
                                        </span>
                                    </div> }
                                {isAuthenticated ?
                                    <div className={classes.user_big_cnt}>
                                        <div className={classes.user} onClick={toggleUserAct}>
                                            <span className={classes.logged_in_cntry_code}>
                                                +234
                                                <span className={classes.loggeed_in_nmbr}>
                                                    {mobileNumber.slice(3)}
                                                </span>
                                            </span>
                                            <div className={classes.arrow + ' ' + [userDropDown && classes.userAct_open]} />
                                        </div>
                                        <div className={classes.user_mngt + ' ' + [userDropDown && classes.user_mngt_open]}>
                                            <div className={classes.user_mngt_content}>
                                                <Link className={classes.mngt_part} to="/Dashboard/Profile">
                                                    <img src={user_icon} alt='toggle icon' loading="lazy" /><span className={classes.mngt_label}>my account</span>
                                                </Link>
                                                <div className={classes.mngt_separator} />
                                                <div className={classes.mngt_part} onClick={logout_fct}>
                                                    <img src={logout_icon} alt='toggle icon' loading="lazy" /><span className={classes.mngt_label + ' ' + classes.logout_label}>logout</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    : <button className={classes.loginBtn} onClick={() => handlePopupToggle("signInSignUp")}>sign in / sign up</button>
                                }
                            </div>
                        </nav>
                        <div
                            className={classes.burgermenu_container}
                            onClick={openMobileMenu}
                        >
                            <i
                                className={`${btnstate === "open" ? classes.openbtn : classes.closebtn
                                    }`}
                            ></i>
                            <i
                                className={`${btnstate === "open" ? classes.openbtn : classes.closebtn
                                    }`}
                            ></i>
                            <i
                                className={`${btnstate === "open" ? classes.openbtn : classes.closebtn
                                    }`}
                            ></i>
                        </div>
                    </div>
                </div>
            </header>
            {activePopup === "reset-password" && <ResetPassword title="reset password" type="reset_cnt" />}
            {activePopup === "resetOtp_pass" && <ResetPassNmbr title="reset password" type="reset_cnt" />}
            {activePopup === "otp" && <Otp title="open free account" type="payment_profile" />}
            {activePopup === "editProfile" && <EditProfile title="edit your profile" type="edit_profile" />}
            {activePopup === "editEmail" && <EditEmail title="update your email" type="edit_profile" />}
            {activePopup === "paymentProfile" && <PaymentProfile title="payment profile" type="payment_profile"/>}
            {activePopup === "payment_pfl_complete" && <SuccessError title="incomplete payment profile" messageType="error" message="you should complete your payment profile before cashout" type="payment_profile" />}
            {activePopup === "responseSuccess" && <PaymentResponse type="payment_success" />}
            {activePopup === 'paystackResponseUssd' && <PaystackResponse option="ussd" amount={paystackAmount} ussdShortcode={ussdPaystackShortCode} type="payment_response" />}
            {activePopup === 'paystackResponse' && <PaystackResponse option="bank" accountNumber={paystackAccountNumber} accountName={paystackAccountName} bankName={paystackBankName} amount={paystackAmount} title="your details" type="payment_response" />}
            {activePopup === 'paystackWithdraw' && <PaystackResponse option="otpVerification" paymentReference={paymentReference} title="your details" type="payment_response" />}
            {activePopup === 'signInSignUp' && <SignInSignUp title="sign in sign up" type="signInSignUp"/>}
            {activePopup === 'estimatePopup' && <EstimatePopup type="estimatePopup"/>}
        </Fragment>
    )
}
