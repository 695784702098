import React, { useState } from 'react';
import classes from './UserContainers.module.scss';
import InputField from '../../components/InputField/InputField';
import { usePopup } from '../../utils/PopupContext';
import axios from 'axios';
import { forgotPasswordApi } from '../../utils/Api';
import Loader from '../Loader/Loader';
import Returnmessage from '../Returnmessage/Returnmessage';

export default function ResetPassword({ type, title }) {
    const { closePopup } = usePopup();
    const [password, setPassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [typeError, setType] = useState('');

    const submitPassReset = (e) => {
        e.preventDefault();
        if (otp && password && confirmPassword && localStorage.getItem('UNVERIFIED_PHNMBR')) {
            if (password != confirmPassword) {
                setLoading(false);
                setError("Password and Confirm Password don't match");
                setType('error');
                setTimeout(() => {
                    setType('');
                    setError('');
                },1000)
                setLoading(null);
            }
            else if (password.length < 6) {
                setError('Password should be a minimum of 6 characters long');
                setType('error');
                setTimeout(() => {
                    setType('');
                    setError('');
                },1000)
            }
            else {
                const authData = {
                    accessChannel: 'WEB',
                    msisdn: localStorage.getItem('UNVERIFIED_PHNMBR'),
                    newPassword: password,
                    confirmPassword: confirmPassword,
                    otp: otp,
                };

                setLoading(true);

                let url = forgotPasswordApi;
                axios
                    .post(url, authData)
                    .then((response) => {
                        setLoading(false);
                        if (response.data.status === 0) {
                            setError('successfuly updated profile informations');
                            setType('success')
                            setTimeout(() => {
                                setError('');
                                closePopup();
                            },2000)
                            setLoading(null);
                        } else {
                            setError(response.data.message);
                            setType('error');
                            setTimeout(() => {
                                setType('');
                                setError('');
                            },1000)
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        }
    }

    return (
        <div className='overlay'>
            <div className={`${classes.auth_cnt} ${classes[type]}`}>
                <div className={classes.content}>
                    <div className={classes.header}>
                        <span className={classes.title}>{title}</span>
                        <div className={classes.close_btn} onClick={closePopup}>
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                        </div>
                    </div>
                    <div className={classes.auth_body}>
                        <form className={classes.right_section} onSubmit={submitPassReset}>
                            <span className={classes.action_label}>OTP code has been sent</span>
                            <InputField required={true} label="Otp number" type="number" placeholder="Enter your OTP" inputValueProp={otp} onChangeInput={setOtp} />
                            <InputField required={true} label="new password" type="password" placeholder="Enter your password ***" inputValueProp={password} onChangeInput={setPassword} />
                            <InputField required={true} label="confirm password" type="password" placeholder="Re-Enter your password ***" inputValueProp={confirmPassword} onChangeInput={setconfirmPassword} />
                            <div className={classes.action_cnt_sm}>
                                <button className={classes.sign_in_btn} type='submit'>{!loading ? 'confirm' : <Loader/>}</button>
                            </div>
                        </form>
                    </div>
                    {error !== '' && <Returnmessage type={typeError} message={error}/>}
                </div>
            </div>
        </div>
    )
}
