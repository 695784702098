import React, { useState } from 'react'
import classes from './UserContainers.module.scss';
import Table from '../Table/Table';
import remove_icon from '../../assets/Icons/remove_icon.svg';
import add_icon from '../../assets/Icons/add_icon.svg';
import { usePopup } from '../../utils/PopupContext';

export default function EstimatePopup({ type }) {
    const { closePopup } = usePopup();
    const [count, setCount] = useState(100);

    const increment = () => setCount(prevCount => prevCount + 100);

    const decrement = () => setCount(prevCount => Math.max(prevCount - 100, 100));

    const data = [
        { game: "Direct1", oddsString: "40.0", oddsNumber: 40.0, winnings: "" },
        { game: "Direct2", oddsString: "240.0", oddsNumber: 240.0, winnings: "" },
        { game: "Direct3", oddsString: "2,100.0", oddsNumber: 2100.0, winnings: "" },
        { game: "Direct4", oddsString: "6,000.0", oddsNumber: 6000.0, winnings: "" },
        { game: "Direct5", oddsString: "44,000.0", oddsNumber: 44000.0, winnings: "" },
        { game: "Perm2", oddsString: "240.0", oddsNumber: 240.0, winnings: "" },
        { game: "Perm3", oddsString: "2,100.0", oddsNumber: 2100.0, winnings: "" },
        { game: "Banker", oddsString: "4 * 240.0", oddsNumber: 960, winnings: "" }
    ];
    const titles = ["Game", "Odds", "Winnings"];

    const keyMap = {
        "Game": "game",
        "Odds": "oddsString",
        "Winnings": "winnings"
    };

    return (
        <div className='overlay'>
            <div className={`${classes.auth_cnt} ${classes[type]}`}>
                <div className={classes.content}>
                    <div className={classes.header}>
                        <span className={classes.title}>winning charts</span>
                        <div className={classes.close_btn} onClick={closePopup}>
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                        </div>
                    </div>
                    <div className={classes.simulation_cnt}>
                        <span className={classes.subTitle}>Enter a bet to simulate win</span>
                        <div className={classes.simulation_actions}>
                            <div className={classes.action_btn_stm + ' ' + classes.minus_btn} onClick={decrement}>
                                <img src={remove_icon} alt='' className={classes.act_icon} loading="lazy" />
                            </div>
                            <div className={classes.board_cnt}>
                                <span className={classes.bet}>{count}</span>
                                <span className={classes.currency}>{process.env.REACT_APP_CURRENCY}</span>
                            </div>
                            <div className={classes.action_btn_stm + ' ' + classes.add_btn} onClick={increment}>
                                <img src={add_icon} alt='' className={classes.act_icon} loading="lazy" />
                            </div>
                        </div>
                    </div>
                    <Table
                        data={data}
                        type="multiplier"
                        bet={count}
                        titles={titles}
                        keyMap={keyMap}
                    />
                </div>
            </div>
        </div>
    )
}
