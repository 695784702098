import React, { useRef, useState } from 'react'
import classes from './UserContainers.module.scss';
import { usePopup } from '../../utils/PopupContext';
import { validateOtp } from '../../utils/Api';
import ApiClients from '../../utils/ApiClients';
import Returnmessage from '../Returnmessage/Returnmessage';
import Loader from '../Loader/Loader';

export default function PaystackResponse({ type, title, accountNumber, accountName, bankName, amount, option, paymentReference, ussdShortcode }) {
    const { closePopup } = usePopup();
    const [inputValues, setInputValues] = useState(["", "", "", ""]);
    const [loading, setLoading] = useState(false);
    const [returnMessage, setReturnMessage] = useState('');
    const [status, setStatus] = useState('');
    const inputRefs = useRef([]);

    const handleInputChange = (index, value) => {
        if (/^\d?$/.test(value)) {
            const updatedValues = [...inputValues];
            updatedValues[index] = value;
            setInputValues(updatedValues);

            if (value && index < inputValues.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === "Backspace" && inputValues[index] === "") {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    const inputs = inputValues.map((value, index) => (
        <input
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            type="text"
            maxLength="1"
            value={value}
            onChange={(e) => handleInputChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            className={classes.input_item}
        />
    ));

    const submitVerification = (e) => {
        e.preventDefault();
        let token = localStorage.getItem('token');
        let url = validateOtp(inputValues?.join(''), paymentReference, token);
        if (inputValues) {
            setLoading(true);
            ApiClients.get(url).then((res) => {
                setLoading(false);
                console.log(res);
                if (res.data.status === '0') {
                    setReturnMessage(res.data.message);
                    setStatus('success');
                    setTimeout(() => {
                        setReturnMessage('');
                        setStatus('');
                        closePopup();
                    },2000)
                } else {
                    setReturnMessage(res.data.message);
                    setStatus('error');
                }
            }).catch((res) => {
                console.log(res);
            })
        }
    }

    return (
        <div className='overlay'>
            <div className={`${classes.auth_cnt} ${classes[type]}`}>
                <div className={classes.content}>
                    <div className={classes.header}>
                        <span className={classes.title}>{title}</span>
                        <div className={classes.close_btn} onClick={closePopup}>
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                        </div>
                    </div>
                    <div className={classes.auth_body}>
                        <div className={classes.status_paystack}>
                            <span className={classes.status_inside}>
                                <i className={classes.point} />
                                <i className={classes.point} />
                                <i className={classes.point} />
                            </span>
                        </div>
                        <span className={classes.status_paystack_lbl}>pending</span>
                        {option === 'bank' ?
                            <div className={classes.info_paystack}>
                                <div className={classes.row_paystack}>
                                    <span className={classes.row_paystack_label}>account number</span>
                                    <span className={classes.row_paystack_value}>{accountNumber}</span>
                                </div>
                                <div className={classes.separator} />
                                <div className={classes.row_paystack}>
                                    <span className={classes.row_paystack_label}>account name</span>
                                    <span className={classes.row_paystack_value}>{accountName}</span>
                                </div>
                                <div className={classes.separator} />
                                <div className={classes.row_paystack}>
                                    <span className={classes.row_paystack_label}>bank name</span>
                                    <span className={classes.row_paystack_value}>{bankName}</span>
                                </div>
                            </div>
                            : option === 'otpVerification' ?
                                <>
                                    <span className={classes.otp_title_bg}>We just texted you</span>
                                    <span className={classes.otp_title_sm}>Please type the otp code sent to you</span>
                                    <div className={classes.inputsContainer}>
                                        {inputs}
                                    </div>
                                    <button className={classes.verify_btn} onClick={(e) => submitVerification(e)}>{loading ? <Loader /> : 'Verify'}</button>
                                </>
                                :
                                <div className={classes.info_paystack}>
                                    <div className={classes.row_paystack}>
                                        <span className={classes.row_paystack_label}>USSD shortcode</span>
                                        <span className={classes.row_paystack_value}>{ussdShortcode}</span>
                                    </div>
                                </div>
                        }
                        {option !== 'otpVerification' &&
                            <div className={classes.amount_paystack_cnt}>
                                <span className={classes.amount_lbl}>amount</span>
                                <span className={classes.amount_value}>{amount}<span className={classes.currency_paystack}>{process.env.REACT_APP_CURRENCY}</span></span>
                            </div>}
                        {returnMessage !== '' && <Returnmessage type={status} message={returnMessage} />}
                    </div>
                </div>
            </div>
        </div>
    )
}
