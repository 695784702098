import React from 'react';
import classes from './UserContainers.module.scss';
import { usePopup } from '../../utils/PopupContext';
import successIcon from '../../assets/Icons/payment_sucess.png';
import bg_coins from '../../assets/Account-cards/coins-blurred.png';

export default function PaymentResponse({ type }) {
    const { closePopup } = usePopup();
    return (
        <div className='overlay'>
            <div className={`${classes.auth_cnt} ${classes[type]}`}>
                <div className={classes.content}>
                    {/* <div className={classes.header}>
                        <div className={classes.close_btn} onClick={closePopup}>
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                        </div>
                    </div> */}
                    <div className={classes.auth_body}>
                        <img className={classes.successDeposit_coins} alt='' src={bg_coins}/>
                        <img src={successIcon} className={classes.successIcon} alt=''/>
                        <span className={classes.sucessDeposit_title}>Deposit Sucess!</span>
                        <span className={classes.sucessDeposit_message}>Your transaction has been successfuly done.</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
