import React, { useState } from 'react';
import classes from './UserContainers.module.scss';
import InputField from '../../components/InputField/InputField';
import { usePopup } from '../../utils/PopupContext';
import axios from 'axios';
import { resendOTP } from '../../utils/Api';
import Loader from '../Loader/Loader';
import Returnmessage from '../Returnmessage/Returnmessage';

export default function ResetPassNmbr({ type, title }) {
    const { handlePopupToggle, closePopup } = usePopup();
    const [mobileNmbr, setMobileNmbr] = useState('');
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);

    const submitPhoneNb = (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        let url = resendOTP(mobileNmbr, 'forgetPassword');
        axios
            .post(url)
            .then((response) => {
                if (response.data.status === 0) {
                    setLoading(false);
                    closePopup();
                    handlePopupToggle('reset-password');
                    localStorage.setItem('UNVERIFIED_PHNMBR',mobileNmbr);
                } else {
                    setLoading(false);
                    setError(response.data.message);
                }
            })
            .catch((err) => {
                setLoading(false);
                setError(err);
            });
    };

    return (
        <div className='overlay'>
            <div className={`${classes.auth_cnt} ${classes[type]}`}>
                <div className={classes.content}>
                    <div className={classes.header}>
                        <span className={classes.title}>{title}</span>
                        <div className={classes.close_btn} onClick={closePopup}>
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                        </div>
                    </div>
                    <div className={classes.auth_body}>
                        <form className={classes.right_section} onSubmit={(e) => submitPhoneNb(e)}>
                            <InputField required={true} label="Phone Number" type="number" Ismobile={true} placeholder="Enter your phone number ***" inputValueProp={mobileNmbr} onChangeInput={setMobileNmbr} />
                            <div className={classes.action_cnt_sm}>
                                <button className={classes.sign_in_btn} type='submit'>{loading ? <Loader /> : 'send OTP'}</button>
                            </div>
                            <div style={{margin:'15px 0'}}>
                            {error && <Returnmessage type="error" message={error} />}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
