import React from 'react'
import classes from './UserContainers.module.scss';
import Returnmessage from '../Returnmessage/Returnmessage';
import { usePopup } from '../../utils/PopupContext';
import { useNavigate } from 'react-router-dom';

export default function SuccessError({ title, type, message, messageType }) {
    const { closePopup } = usePopup();
    const navigate = useNavigate();
    return (
        <div className='overlay'>
            <div className={`${classes.auth_cnt} ${classes[type]}`}>
                <div className={classes.content}>
                    <div className={classes.header}>
                        <span className={classes.title}>{title}</span>
                        {type !== 'payment_profile' && <div className={classes.close_btn} onClick={closePopup}>
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                        </div>}
                    </div>
                    <div className={classes.auth_body}>
                        <Returnmessage type={messageType} message={message} />
                    </div>
                    {type === 'payment_profile' && <div className={classes.complete_cnt}><button className={classes.complete_btn} onClick={() => {
                        closePopup();
                        navigate('/Dashboard/Profile');
                    }}>complete now</button></div>}
                </div>
            </div>
        </div>
    )
}