import React, { useState, useRef } from 'react';
import classes from './UserContainers.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { verifyMsisdn } from '../../store/authSlice';
import Loader from '../Loader/Loader';
import { usePopup } from '../../utils/PopupContext';
import Returnmessage from '../Returnmessage/Returnmessage';
import axios from 'axios';
import { resendOTP } from '../../utils/Api';

export default function Otp({ type, title }) {
    const [returnMessage, setReturnMessage] = useState(null);
    const [inputValues, setInputValues] = useState(["", "", "", ""]);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);
    const dispatch = useDispatch();
    const optLoader = useSelector(state => state.auth.loader);
    const message = useSelector(state => state.auth.message);
    const { closePopup } = usePopup();
    const inputRefs = useRef([]);

    const handleInputChange = (index, value) => {
        if (/^\d?$/.test(value)) {
            const updatedValues = [...inputValues];
            updatedValues[index] = value;
            setInputValues(updatedValues);

            if (value && index < inputValues.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === "Backspace" && inputValues[index] === "") {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };


    const inputs = inputValues.map((value, index) => (
        <input
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            type="text"
            maxLength="1"
            value={value}
            onChange={(e) => handleInputChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            className={classes.input_item}
        />
    ));

    const submitVerification = (event) => {
        event.preventDefault();
        if (inputValues) {
            dispatch(verifyMsisdn(inputValues?.join(''), closePopup));
        }
        else {
            setReturnMessage("OTP incorrect");
        }
    }

    const resendCode = (event) => {
        event.preventDefault();
        setReturnMessage("");
        setLoading(true);
        let msisdn = localStorage.getItem('unverified_msisdn');

        axios.post(resendOTP(msisdn, 'register'))
            .then(response => {
                setLoading(false);
                if (response.data.status === 0) {
                    setStatus(true);
                    setReturnMessage(response.data.message);
                    setTimeout(() => {

                    }, 2000)
                }
                else {
                    setStatus(false);
                    setReturnMessage(response.data.message);
                }
            })
            .catch(err => {
                setLoading(false);
                setStatus(1);
                setReturnMessage(err);
            });
    };

    return (
        <div className='overlay'>
            <div className={`${classes.auth_cnt} ${classes[type]}`}>
                <div className={classes.content}>
                    <div className={classes.header}>
                        <span className={classes.title}>{title}</span>
                        <div className={classes.close_btn} onClick={closePopup}>
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                        </div>
                    </div>
                    <form className={classes.form} onSubmit={submitVerification}>
                        <span className={classes.otp_title_bg}>We just texted you</span>
                        <span className={classes.otp_title_sm}>Please type the otp code sent to you</span>
                        {message ? <Returnmessage type="error" message={message} /> : [returnMessage && status] ? <Returnmessage type="success" message={returnMessage} /> : [returnMessage && !status] ? <Returnmessage type="error" message={returnMessage} /> : <></>}
                        <div className={classes.inputsContainer}>
                            {inputs}
                        </div>
                        <button className={classes.resend_btn} onClick={resendCode}>{loading ? <Loader /> : 'resend otp'}</button>
                        <button className={classes.next_btn} type='submit'>{optLoader ? <Loader /> : 'Next'}</button>
                    </form>
                </div>
            </div>
        </div>
    )
}
