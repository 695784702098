import axios from 'axios';
import { refreshTokenApi } from './Api';
import { usePopup } from './PopupContext';
import { getPopupHandler } from './PopupHandler';
const { REACT_APP_API_ENDPOINT } = process.env;
axios.defaults.baseURL = REACT_APP_API_ENDPOINT;

const ApiClients = axios.create({
    baseURL: REACT_APP_API_ENDPOINT,
});

const refreshTokenFct = async () => {
    let authBody = {
        refreshToken: localStorage.getItem('refreshToken')
    }
    let token = localStorage.getItem('token')

    try {
        const response = await axios.post(refreshTokenApi(localStorage.getItem('clientId')), authBody, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        const newToken = response.data.token.token;
        const newRefreshToken = response.data.refreshToken.token;
        const newExpiry = response.data.refreshToken.ttl;

        if (!newToken) {
            return logout();
        }
        localStorage.setItem('token', newToken);
        localStorage.setItem('refreshToken', newRefreshToken);
        localStorage.setItem('tokenExpiry', newExpiry);
        localStorage.setItem('tokenIssueTime', new Date());
        return newToken;
    } catch (error) {
        console.error('Failed to refresh token', error);
        return logout();
    }
};

const isTokenExpiring = () => {
    const tokenExpiryDate = new Date(localStorage.getItem('tokenExpiry'));
console.log('tokenExpiryDate',tokenExpiryDate)
    if (!tokenExpiryDate) {
        return true;
    }
    const currentDate = new Date();
    const remainingTime = tokenExpiryDate.getTime() - currentDate.getTime();
    const totalLifespan = tokenExpiryDate.getTime() - new Date(localStorage.getItem('tokenIssueTime')).getTime();

    if (remainingTime <= 0) {
        return logout();
    }

    console.log('remain',remainingTime);
    console.log('total life',totalLifespan * 0.2);
    console.log('true?',remainingTime <= totalLifespan * 0.98);

    return remainingTime <= totalLifespan * 0.98;
};

const logout = () => {
    const popupHandler = getPopupHandler();
    localStorage.clear();
    window.location.href = '/';
    if (popupHandler) {
        popupHandler("login");
    }
}

ApiClients.interceptors.request.use(async (config) => {
    let token = localStorage.getItem('token');
    if (token) {
        console.log('token',token);
        if (isTokenExpiring()) {
            token = await refreshTokenFct();
        }
        config.headers.Authorization = `Bearer ${token}`;
    }
    else {
        console.warn('Token is not available or failed to refresh');
    }
    return config;
}, (error) => {
    // return Promise.reject(error);
});

export default ApiClients;
