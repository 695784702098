import React from "react";
import classes from "./Loader.module.scss";

export default function Loader({ type }) {
    return (
        <div className={`${classes.loader_container} ${classes[type]}`} />
    );
}

export function ErrorMessage(message) {
	return (
		<div className={`${classes.message_container} ${classes.error}`}>
			{message ? message : 'Error retrieving data!'}
		</div>
	);
}

