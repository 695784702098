import React from 'react';
import classes from './GameList.module.scss';
import { Link } from 'react-router-dom';

export default function GameList({ img, promoTxt, link, buttonText, type, linkedArticle }) {
  return (
    <div className={`${classes.media_element} ${classes.snaps_inline}`}>
      <div className={classes.media_bg}>
        <div className={classes.promo_text_cnt}>
          <span className={classes.promo_text}>{promoTxt}</span>
        </div>
        <img className={classes.media_img} src={img} alt="" loading="lazy" />
      </div>
      {type === 'article' ? (
        <Link to={`/articles?articleId=${linkedArticle}&title=${promoTxt}`} className={classes.promo_btn}>
        {buttonText}
      </Link>
      ) : (
        <a className={classes.promo_btn} href={link} target="_blank" rel="noopener noreferrer">
          {buttonText}
        </a>
      )}
    </div>
  );
}
